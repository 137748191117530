/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { replaceSubdomain } from '../../utils/replace-subdomain';

type SEOProps = {
  authorized_roles?: string[];
  contentType?: 'pages' | 'stories';
  description?: string;
  first_published_at?: string;
  lang?: string;
  og_description?: string;
  og_image?: string;
  og_title?: string;
  slug?: string;
  story_type?: string;
  teaser_image?: string;
  title?: string;
  twitter_description?: string;
  twitter_image?: string;
  twitter_title?: string;
  teaser_title?: string;
  teaser_description?: string;
  releaseType?: 'media' | 'investors';
  releaseDate?: string;
};

export function SEO({
  title,
  description,
  lang,
  slug,
  contentType,
  /* TODO: Standardise naming convention of component props to use camelCase */
  /* eslint-disable @typescript-eslint/naming-convention */
  og_image,
  og_title,
  og_description,
  twitter_image,
  twitter_title,
  twitter_description,
  authorized_roles,
  story_type,
  first_published_at,
  teaser_image,
  teaser_title,
  teaser_description,
  releaseType,
  releaseDate,
  /* eslint-enable @typescript-eslint/naming-convention */
}: SEOProps): JSX.Element {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            url
            defaultLanguage
            twitterHandle
          }
        }
      }
    `,
  );
  const isPrivate = (authorized_roles !== undefined) && (authorized_roles.includes('authorized')) ? 'yes' : 'no';
  const websiteUrl = replaceSubdomain(process.env.GATSBY_WEBSITE_URL);
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || teaser_title || og_title}
      meta={[
        {
          name: 'title',
          content: title,
        },
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:image',
          content: og_image || teaser_image,
        },
        {
          property: 'og:title',
          content: og_title || title,
        },
        {
          property: 'og:description',
          content: og_description.trim() || description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: `${websiteUrl}/${slug}`,
        },
        {
          name: 'twitter:image',
          content: twitter_image,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.twitterHandle,
        },
        {
          name: 'twitter:title',
          content: twitter_title,
        },
        {
          name: 'twitter:description',
          content: twitter_description,
        },
        {
          name: 'private',
          content: isPrivate,
        },
        {
          name: 'content-type',
          content: contentType,
        },
        {
          name: 'story-type',
          content: story_type,
        },
        {
          name: 'published-date',
          content: first_published_at,
        },
        {
          name: 'teaser-image',
          content: teaser_image,
        },
        {
          name: 'teaser-title',
          content: teaser_title,
        },
        {
          name: 'teaser-description',
          content: teaser_description,
        },
        {
          name: 'release-type',
          content: releaseType,
        },
        {
          name: 'release-date',
          content: releaseDate,
        },
      ]}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
  title: '',
  description: '',
  og_image: '',
  og_title: '',
  og_description: '',
  twitter_image: '',
  twitter_title: '',
  twitter_description: '',
  slug: '',
};
